<template>
	<div class="main">
		<top-bar></top-bar>
		<!-- 内容 -->
		<div class="main-body">
			<div class="main-body-content">
				<!-- <router-view></router-view> -->
				<PageBuild></PageBuild>
			</div>
		</div>
	</div>
</template>

<script>
import TopBar from '@/components/TopBar';
import { mapMutations, mapGetters } from 'vuex';
import { settingProject } from '@/utils/auth';
import PageBuild from './page-build';
export default {
	name: 'mall',

	components: {
		TopBar,
		PageBuild,
	},

	created() {
		//     Vue.config.errorHandler = function (err, vm, info) {
		//   let {
		//       message,
		//       name,
		//       script,
		//       line,
		//       column,
		//       stack
		//   } = err;
		//     console.log('errorMessage: ' + message); // 异常信息
		//     console.log('scriptURI: ' + script); // 异常文件路径
		//     console.log('lineNo: ' + line); // 异常行号
		//     console.log('columnNo: ' + column); // 异常列号
		//     console.log('error: ' + stack); // 异常堆栈信息
		//     console.log('name: ' + name); // 异常堆栈信息
		//   // info为 Vue 特定的错误信息，比如错误所在的生命周期钩子
		// }
		// 默认设置首页为当前正在构建页面
		this.setHomePage();

		// 利用闭包保存project数据,在刷新时保存
		let temp = this.project;

		// 当页面刷新时，把当前项目数据保存localStorage中
		window.addEventListener('beforeunload', function(e) {
			settingProject(temp);
		});
	},

	data() {
		return {
			meauList: [
				// {
				//   name: "主导航",
				//   icon: "icon-shop",
				//   path: "/mall/store",
				// },
				// {
				//   name: "页面",
				//   icon: "icon-fuzhiyemian",
				//   path: "/mall/pages-manage",
				// },
				// {
				//   name: "文章",
				//   icon: "icon-shangpin-meau",
				//   path: "/mall/goods",
				// },
				{
					name: '组件',
					icon: 'icon-list',
					path: '/mall/page-build',
				},
				{
					name: '模板',
					icon: 'icon-shangpin-meau',
					path: '/mall/model-manage',
				},
			],
		};
	},

	computed: {
		...mapGetters(['project']),
	},

	methods: {
		...mapMutations(['setHomePage']),
	},
};
</script>

<style lang="scss" scoped>
.main {
	display: flex;
	height: 100vh; /*no*/
	background: #f7f8fa;
	overflow: hidden;
	.main-body {
		flex: 1;

		.main-body-content {
			position: relative;
			height: 100%; /*no*/
			margin-top: 4px;
			overflow: hidden;
			padding-top: 50px;
		}
	}
}

.main-meau {
	width: 70px; /*no*/
	height: 100%;
	padding-top: 16px; /*no*/
	border-right: 1px solid #ebedf0; /*no*/
	// background: #22242f;
	background: #fafafa;

	.main-meau-item {
		display: flex;
		align-items: center;
		width: 100%;
		font-size: 14px; /*no*/
		padding: 16px 0;
		color: #7d7e80;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		i {
			font-size: 18px; /*no*/
			color: #7d7e80;
		}
	}

	.active {
		i {
			color: #197aff !important;
		}
		.main-meau-item {
			position: relative;
			color: #197aff !important;
			background: #fff;
			// &::after {
			//   position: absolute;
			//   top: 10px; /*no*/
			//   right: 10px; /*no*/
			//   content: "";
			//   width: 4px; /*no*/
			//   height: 21px; /*no*/
			//   border-radius: 3px; /*no*/
			//   background: $color-theme;
			// }
		}
	}
}
</style>
