<!--
 * @Description: 保存
 * @Autor: WangYuan
 * @Date: 2021-09-27 16:53:55
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-08-31 16:35:54
-->
<template>
  <el-dialog :visible.sync="show" :append-to-body="true" top="50px" :close-on-click-modal="false" width="40%">
    <div class="flex-column col-center">
      <!-- logo -->
      <img class="w100 mb20 " :src="project.logo" />

      <!-- form -->
      <el-form
        :model="project"
        ref="form"
        label-width="120px"
        style="width: 360px"
      >
        <el-form-item label="模板名称" prop="name" verify>
          <el-input type="text" v-model="project.name" ></el-input>
        </el-form-item>
          <el-form-item label="模板分类"  verify>
           <el-select v-model="project.industry" placeholder="请选择">
            <el-option
              v-for="item in list"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- 封面 -->
      <div class="cover">
        <div class="cover-title">
         
          <UploadButton  @success="success" />
          <span class="ml10 lb-1 f-theme">点击上传模板封面</span>
        </div>
        <div class="cover-img">
          <img class="w-100" :src="project.cover" />
        </div>
      </div>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button  round style="width: 140px" @click="submit(0)"
        >保存当前模板</el-button
      >
      <el-button type="primary" round style="width: 140px" @click="submit(1)"
        >发布为新模板</el-button
      >
    </div>

    <home-cover ref="home-cover" @complete="complete"></home-cover>
  </el-dialog>
</template>

<script>
import HomeCover from "./HomeCover.vue";
import { postTemplate,editTemplate } from "@/api/project";
import { mapGetters } from "vuex";
import { getModelType } from "@/api/project";
import UploadButton from "@/components/UploadButton"
export default {
  name: "SaveDialog",

  components: {
    HomeCover,
    UploadButton
  },

  inject: ["topBar"],

  data() {
    return {
      show: false,
      view: false,
      list:[]
    };
  },

  computed: {
    ...mapGetters(["project", "userInfo"]),
  },

  methods: {
    open(view) {
      getModelType().then(res=>{
        this.list = res.data
         this.show = true;
          this.view = view;
      })
    },
    success(e){
      console.log(e);
      this.project.cover=e.data.url
    },
    /**
     * 更新封面
     * 成功更新封面图片,失败则更新为默认图片
     */
    changeCover() {
      this.$refs["home-cover"].init();
    },

    complete(result) {
      console.log("complete.....");
      console.log(result);

      if (result.status == 1) {
        this.$set(this.project, "cover", result.data);
        this.$notify({
          title: "成功",
          message: "封面生成成功!",
          type: "success",
        });
      } else {
        this.$set(
          this.project,
          "cover",
          "http://110.42.184.128:8090/img/1639553326077.jpeg"
        );
        this.$notify({
          title: "失败",
          message: "封面生成失败,显示默认封面",
          type: "warning",
        });
      }
    },

    submit(i) {
      if(i){
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.save();
          }
        });
      }else{
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.edit();
          }
        });
      }
      
    },
    //修改
    edit(){
       let form = this.$cloneDeep(this.project)
    form.is_template=1
    if(form.template_id){
      form.id=form.template_id
      delete form.template_id
        editTemplate(form).then(res=>{
          if(res.code==200){
            this.$notify({
              title: "成功",
              message: "修改模板成功",
              type: "success",
            });
            this.openSave(true);
          }
        })
    }else{
       this.$notify({
              title: "警告",
              message: "请选择模板后重试",
              type: "error",
            });
    }
    
    },
    // 保存
    save() {
    let form = this.$cloneDeep(this.project)
    form.is_template=1
    delete form.id
    console.log(JSON.stringify(form))
      postTemplate(form).then(res=>{
        if(res.code==200){
           this.$notify({
            title: "成功",
            message: "发布模板成功",
            type: "success",
          });
           this.openSave(true);
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 0;
  margin-bottom: 5px;
}

::v-deep .el-form-item {
  padding-bottom: 5px;
  border-bottom: solid 1px #e3e3e3;

  .el-form-item__label {
    text-align: left;
  }

  .el-form-item__error {
    top: 47px;
    left: -80px;
  }
}

::v-deep .el-button--primary {
  background: $color-theme !important;
}

.cover {
  position: relative;

  .cover-title {
    margin: 10px 0 15px 0;
    display: flex;
    align-items: center;
  }

  .cover-img {
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .cover-btn {
    position: absolute;
    top: 40px;
    right: -120px;
    z-index: 1;
  }
}
</style>
