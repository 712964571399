<template>
  <div>
    <el-upload
      :disabled="loading"
      :headers="headers"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :with-credentials="true"
      :show-file-list="false"
      :file-list="fileList"
      :action="action"
      :accept="accept"
      ref="upload"
    >
       <el-button
            icon="el-icon-refresh-right"
            circle
            :loading="loading"
        ></el-button>
     
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: '点击上传'
    },
    accept: {
      type: String,
      required: false,
      default: ''
    },
    btntype:{
      type:String,
      required:false,
      default:'primary'
    }
  },
  data() {
    return {
      action: '/api/common/upload?is_cdn=1',
      headers: {
        // Authorization: getToken()
      },
      loading: false,
      fileList: this.value || []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.fileList = val
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleRemove(file,index){
      console.log(file,index);
      this.fileList.splice(index,1)
    },
    onSuccess(response, file, fileList) {
      // this.fileList.push({
      //   url: response.url,
      //   name: file.name
      // })
      this.loading = false
      this.$emit('success', response)
    },
    beforeUpload(file) {
      if (this.loading) {
        return false
      }
      this.loading = true
      this.$emit('beforeUpload', file)
      return true
    },
    download(url) {
      console.log(url)
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.file {
  &-item {
    width: 100%;
    height: 28px;
    cursor: pointer;
    font-size: 14px;
    color: #909399;
    margin-bottom: 3px;
    i {
      margin-right: 3px;
    }
    span {
      flex: 1;
      color: #1890ff;
    }
    .right {
      display: none;
      margin-right: 12px;
    }
  }
  &-item:hover {
    background: #f5f7fa;
    .right {
      display: block;
    }
  }
}
</style>
